
// Types
import type {
  Cart,
  CartItem,
  CheckoutCreateCancellationProtectionCartItem,
  MetaData as HandlePaymentCompleteMetaData,
  Payload as HandlePaymentCompletePayload,
  PaymentSummaryProps,
  User as HandlePaymentCompleteUser,
  CancellationProtection,
} from '@white-label-types/parking-checkout';
import type { PusherEvent } from '@white-label-helper/pusher-handle-payment-request';
import type {
  ExtrasCancellationProtectionProduct,
  POI,
  CancellationPolicy,
  AmendmentPolicy,
} from '@white-label-types/parking-booking';

// Packages
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { Portal } from 'portal-vue';

// Helpers
import {
  gaDataLayer,
  gaDataLayerLounges,
} from '@white-label-helper/ga-data-layer';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { getAppPaymentProvider } from '@white-label-helper/get-app-payment-provider';
import {
  goToApp,
  getDomainUrl,
  MANAGE_BOOKING_URLS,
  DOMAINS_NAMES,
} from '@white-label-helper/switch-app';
import {
  trackBeginCheckout,
  refreshDataLayer,
} from '@white-label-helper/ga-tracking';
import { fetchCartItems } from '@white-label-helper/api-parking-cart';
import { handlePaymentHelper } from '@white-label-helper/pusher-handle-payment-request';
import { continueBookingHelper } from '@white-label-helper/pusher-continue-booking';
import { formatPrice } from '@white-label-helper/helper-payment';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';
import { updateCancellationProtectionRequest } from '@white-label-helper/api-parkings-cart';
import { parseDiscountCodeData } from '@white-label-helper/discount-code-parser';
import { getAppHeroProduct } from '@white-label-helper/get-app-hero-product';
import { getFeatureFlag, trackLaunchDarklyEvent } from '@white-label-plugin/launch-darkly';

// Mixins
import checkout from '@white-label-helper/mixin-checkout';

// Stores
import { usePreloaderControllerStore } from '@white-label-store/preloader-controller';
import { useDiscountCodeStore } from '@white-label-store/discount-code';
import { useCartCheckoutStore } from '@white-label-store/cart-checkout';
import useDepositStore from '@white-label-store/deposits'
import { usePaymentFormStore } from '@white-label-store/payment-form';

// Constants
import {
  APP_HERO_PRODUCTS,
  ContinueBookingStatuses,
  PAYMENT_PROVIDERS,
  NAMED_ROUTES,
  PRODUCT_TYPES
} from '@white-label-configuration/constants';

import { StatusCodes } from 'http-status-codes';

const {
  handlePayment,
  destroyHandlePayment,
  handleBookingOrderWithoutPayment,
} = handlePaymentHelper('original');
const { continueBooking: continueOriginalBooking, destroyContinueBoooking } =
  continueBookingHelper('original');

import BackButton from '../back-button/back-button.vue';
import PaymentForm from '../payment-form/payment-form.vue';
import PaymentFormPreloader from '../payment-form-preloader/payment-form-preloader.vue';
import SummaryLounges from '../summary-lounges/summary-lounges.vue';
import SummaryParking from '../summary-parking/summary-parking.vue';
import PaymentSummary from '../payment-summary/payment-summary.vue';
import SummaryPreloader from '../summary-preloader/summary-preloader.vue';
import StickySidebar from '../sticky-sidebar/sticky-sidebar.vue';
import { addCustomMetric } from '@white-label-helper/shared-helpers';

export default defineComponent({
  name: 'PaymentPage',

  handlePayment,
  destroyHandlePayment,
  continueOriginalBooking,
  destroyContinueBoooking,
  handleBookingOrderWithoutPayment,

  components: {
    BackButton: BackButton,
    PaymentForm: PaymentForm,
    PaymentFormPreloader: PaymentFormPreloader,
    Portal,
    StickySidebar,
    SummaryLounges,
    PaymentSummary,
    SummaryParkings: SummaryParking,
    SummaryPreloader,
  },
  mixins: [checkout],

  data() {
    return {
      loadingPaymentInfo: false,
      $vueInstance: null as Vue | null,
    }
  },

  computed: {
    ...mapState(useCartCheckoutStore, {
      readCartItems: 'readCartItems',
      readCartBundle: 'readCartBundle'
    }),
    ...mapState(useDiscountCodeStore, {
      discountCode: 'discountCode',
      discountObject: 'discountObject',
      discountQuery: 'discountQuery',
    }),
    ...mapState(usePaymentFormStore, {
      readPaymentFormData: 'readPaymentFormData',
    }),
    isMultiBasket(): boolean {
      return !!getAppVariable('is_multi_basket');
    },
    summaryProps(): PaymentSummaryProps {
      return {
        orderName: this.checkout_orderName,
        orderTotal: this.checkout_orderTotalFormatted,
        itemSubtotal: this.checkout_itemSubtotalFormatted,
        itemTotal: this.checkout_itemTotalFormatted,
        entryDateTime: this.checkout_entryDateTime,
        exitDateTime: this.checkout_exitDateTime,
        taxes: this.checkout_taxes,
        fees: this.checkout_fees,
        extras: this.checkout_summaryExtras,
        discount: this.discountObject,
        contentLoading: this.$contentPreloaderEnabled,
        itemTotalOld: formatPrice(
          this.checkout_cartItem?.original_amounts.totals.total
        ),
        bookingFee: this.checkout_bookingFee?.is_enabled
          ? formatPrice(this.checkout_bookingFee.amount)
          : '',
      };
    },
    displayNewSummaries() {
      return this['$launchDarkly'].variation('PT-1566-DISPLAY-NEW-PAYMENT-SUMMARIES');
    },
    displayTravelExtrasSummary(){
      return this['$launchDarkly'].variation('ECOM-1770-Travel-Extras-Payment-Summary');
    },
    cartItems() {
      return this.readCartItems;
    },
    paymentFormData(){
      return this.readPaymentFormData;
    },
    additionalFields() {
      return this['isMultiBasket'] || this.$launchDarkly.variation('ECOM_1877_CROSS_SELL_PRE_PURCHASE')
        ? this.checkout_multiCartAdditionalFields
        : this.checkout_additionalFields;
    },
    cancellationProtection(): CancellationProtection[] {
      // Always treat cancellationProtection as an array
      return this.checkout_multiCartCancellationProtection
    },
    cancellationProtectionProductIds(): string[] {
      return this.cancellationProtection
        .filter((item) => item?.is_available)
        .map((item) => item?.product_id);
    },
    isProtected(): boolean {
      return this.cartItems?.some((item: CartItem) => item.is_protected);
    },
    cancellationPolicies(): CancellationPolicy[] | CartItem['inventory_option']['cancellation_policies'][] {
      return this.checkout_multiCartCancellationPolicies
    },
    amendmentPolicies(): AmendmentPolicy[] | CartItem['inventory_option']['amendment_policies'][] {
      return this.checkout_multiCartAmendmentPolicies
    },
    isBookingFeeCharged(): boolean {
      return this.checkout_bookingFee?.is_enabled || false;
    },
    bundle() {
      return this.readCartBundle;
    },

    /**
     * The cross-sell lounges page is active if:
     * - the hero product is parking
     * - the first cart item had lounges available in its inventory metadata.
     */
    crossSellLoungesPageActive() {
      const heroProduct = getAppHeroProduct(APP_HERO_PRODUCTS.PARKING);
      return heroProduct === APP_HERO_PRODUCTS.PARKING && !!this.cartItems[0]?.meta?.inventory_metadata?.['has_lounges'];
    },

    /**
     * The travel extras page is active if:
     * - the feature flag EM-369-CROSS-SELL-MIXED-RESULTS is active
     * - the hero product is parking
     * - the first cart item had lounges or fassttrack available in its inventory metadata
     */
    travelExtrasPageActive() {
      const heroProduct = getAppHeroProduct(APP_HERO_PRODUCTS.PARKING);
      const hasLounges = !!this.cartItems[0]?.meta?.inventory_metadata?.['has_lounges'];
      const hasFasttrack = !!this.cartItems[0]?.meta?.inventory_metadata?.['has_fasttrack'];
      return this.$launchDarkly.variation('EM-369-CROSS-SELL-MIXED-RESULTS') === true
        && heroProduct === APP_HERO_PRODUCTS.PARKING
        && (hasLounges || hasFasttrack);
      }
  },
  created() {
    this.commitCartItems([]);
  },

  mounted() {
    this.$vueInstance = this.$root;
  },

  methods: {
    ...mapActions(usePreloaderControllerStore, {
      commitUpdatePreloader: 'updatePreloader',
    }),

    ...mapActions(useCartCheckoutStore, {
      commitCartItems: 'commitCartItems',
      commitCartBundle: 'commitCartBundle',
      commitUpdateCancellationProtection: 'commitUpdateCancellationProtection',
    }),

    ...mapActions(useDiscountCodeStore, {
      storeDiscountCode: 'storeDiscountCode',
      storeDeepLinkCode: 'storeDeepLinkCode',
      clearDiscountCode: 'clearDiscountCode'
    }),

    cartHasExtrasProduct() {
      return this.cartItems?.some(
        (item: CartItem) => item.product_code === PRODUCT_TYPES.EXTRAS
      );
    },
    async getCart() {
      try {
        const cartItems = (await fetchCartItems(
          this.checkout_cartToken
        )) as Cart;
        const deposit = useDepositStore()
        deposit.updateCartDepositTotal({
          ...cartItems.payable,
        });
        deposit.updateItemDeposit(cartItems.items)
        this.commitCartItems(cartItems.items);
        if (cartItems.bundle) {
            this.commitCartBundle({
              ...cartItems.bundle,
              items: [...cartItems.items.filter((item) => item.bundle_id === cartItems.bundle.id)],
          });
        }
        // now we get a successful response even for a non-existent cart
        // to check if cart not empty we look at the length of the array
        if (cartItems.items.length) {
          // if we have discount code applied to the cart – put it to store to be displayed
          const discountAppliedToCart = parseDiscountCodeData(cartItems);
          if (discountAppliedToCart) {
            this.storeDiscountCode(discountAppliedToCart);
          }

          const {
            items,
            valid_till,
            is_valid,
            totals,
            original_amounts,
            booking_fee,
          } = cartItems;

          if (deposit.shouldShowDepositInformation) {
            totals['total'] = cartItems.payable.pay_now;
          }

          const item = items[0];
          const couponCode = item.search_criteria?.parking?.coupon_code;

          this.checkout_cartItems = items;
          this.checkout_originalAmounts = original_amounts;
          this.checkout_bookingFee = booking_fee;
          this.storeDeepLinkCode(typeof couponCode !== 'string' ? '' : couponCode);
          this.checkout_total = totals;
          // @ts-ignore – Mixin method
          this.checkout_revalidateCartRecursive(is_valid, true, valid_till);
          this.$global_disableContentPreloader();

          refreshDataLayer(this.$gtm);

          // @ts-ignore
          const query = this.checkout_getQueryForGoBack();
          if (this.isParkingProduct) {
            // If multiple POIs are available, we need to get the correct one
            const poi = getAppVariable('pois').find(
              (poi: POI) => poi.code === query.airport
            );

            this.$gtm.push(
              gaDataLayer({
                airportCode: poi.code,
                airportName: poi.name,
                partnerName: getAppVariable('partner_name'),
                siteLanguage: getAppVariable('default_language'),
                airportTerminalId: query.terminal,
                parkingDepartureDate: query.entryDate,
                parkingDepartureTime: query.entryTime,
                parkingReturnDate: query.exitDate,
                parkingReturnTime: query.exitTime,
                couponVoucher: query.discount || undefined,
                carParkName: this.checkout_orderName,
              })
            );
          }

          if (this.isLoungesProduct) {
            this.$gtm.push(
              gaDataLayerLounges(item.search_criteria?.lounges, {
                loungeName: this.checkout_orderName,
                loginStatus: this.$auth?.state?.loggedIn || false,
                userId: this.$auth?.state?.user?.sub || '',
              })
            );
          }
          const trackingItems = [...items];

          if (item.is_protected) {
            this.updateCancellationProtection();
            // @ts-ignore - Mixin method
            trackingItems.push(
              this.checkout_createCancellationProtectionCartItem(item)
            );
          }

          const metaIndex = item?.meta?.filter?.index;
          const metaItemListName = item?.meta?.filter?.item_list_name;
          const bookingFee = booking_fee?.amount;

          const params =
            metaIndex && metaItemListName
              ? [this.$gtm, trackingItems, bookingFee, metaIndex, metaItemListName]
              : [this.$gtm, trackingItems, bookingFee];

          if (this.isLoungesProduct) {
            params.push('lounges');
          }

          // @ts-ignore - Wrong product type
          trackBeginCheckout(...params);
          // Uncomment if begin_checkout will be part of experiment - with correct flag name
          // trackLaunchDarklyEvent('ECOM_2230_CHECKOUT_FORM_CRO_EXPERIMENT', 'begin_checkout');
        } else {
          // @ts-ignore - Mixin method
          this.checkout_showTechnicalIssueError();
        }
      } catch (error: unknown) {
        let errorResponse = '';
        try {
          errorResponse = JSON.parse(error.message);
        } catch (parseError) {}

        // Session expired
        if (errorResponse?.status === StatusCodes.GONE) {
          // @ts-ignore - Mixin method
          this.checkout_openExpiredModal();
          return;
        }

        // @ts-ignore - Mixin method
        this.checkout_showTechnicalIssueError();
      }
    },

    handlePaymentCompleted(payload: {
      payload: HandlePaymentCompletePayload;
      userDetails: HandlePaymentCompleteUser;
      metaData: HandlePaymentCompleteMetaData;
    }) {
      if (
        getAppPaymentProvider() === PAYMENT_PROVIDERS.STRIPE &&
        this.checkout_orderTotal !== 0
      ) {
        this.continueBooking(ContinueBookingStatuses.Successful);
      } else {
        this.createBooking(payload);
      }
    },

    handleStripePaymentIntentPayload(payload: {
      payload: HandlePaymentCompletePayload;
      userDetails: HandlePaymentCompleteUser;
      metaData: HandlePaymentCompleteMetaData;
    }) {
      this.createBooking(payload);
    },

    createBooking({
      payload,
      userDetails,
      metaData,
    }: {
      payload: HandlePaymentCompletePayload;
      userDetails: HandlePaymentCompleteUser;
      metaData: HandlePaymentCompleteMetaData;
    }) {
      const startTime = performance.now()
         const getOutboundFlight = (userDetails: HandlePaymentCompleteUser, cartItem: CartItem) => {
        // note that this has a single use-case - for TimeSlot to automatically
        // include the flight number on the booking.
        type TimeslotCriteria = { airline_iata?: string, flight_number?: string } | undefined;
        const { flight_number, airline_iata } = cartItem.search_criteria?.[APP_HERO_PRODUCTS.FASTTRACK] as TimeslotCriteria ?? {};
        if (getFeatureFlag('NGQ_11_TimeSlot') && airline_iata && flight_number) return `${airline_iata}${flight_number}`;
        return userDetails.outbound_flight;
      };
      const body = {
        cart_token: this.checkout_cartToken,
        amount: this.checkout_orderTotal,
        currency: this.checkout_currency,
        user: {
          ...userDetails,
          outbound_flight: getOutboundFlight(userDetails, this.checkout_cartItem),
        },
        ...(!isBookingPortal
          ? {
              gateway_data: this.checkout_getGatewayData(payload),
            }
          : {}),
        ...(isBookingPortal
          ? {
              meta: {
                ...metaData,
              },
            }
          : {}),
      };

      this.commitUpdatePreloader(this.$t('UI.fullscreenPreloaderMessages.nearlyThere'));
      if (isBookingPortal) {

        handleBookingOrderWithoutPayment(this.checkout_cartToken, body)
          .then(() => {
            this.logoutBookingPortal();
          }).then(() => {
            addCustomMetric("tracking", "Booking Portal Metric: Success", {
              event: 'BookingSuccess'
            },  startTime, 'booking_service');
          })
          .catch(this.checkout_handlePaymentError);
      } else {
        handlePayment(this.checkout_cartToken, body)
          .then((data: PusherEvent) => {
            // @ts-ignore mixin variable
            this.checkout_forwardInfo = data.forward;

            if (getAppPaymentProvider() === PAYMENT_PROVIDERS.STRIPE && this.checkout_orderTotal !== 0) {
              // @ts-ignore mixin property
              this.checkout_paymentsConfig.token = data.payment_client_secret;
              // @ts-ignore mixin property
              this.checkout_paymentIntentUpdated = true;
            } else {
              this.continueBooking(ContinueBookingStatuses.Successful);
            }
          })
          .catch(this.checkout_handlePaymentError);
      }
    },

    continueBooking(status: string) {
      const startTime = performance.now()
      const body = {
        status,
        // @ts-ignore mixin variable
        forward: this.checkout_forwardInfo,
      };
      continueOriginalBooking(this.checkout_cartToken, body)
        .then(
          ({
            management_token,
            order_reference,
          }: {
            management_token: { token: string; expiration: number };
            order_reference: string;
          }) => {
            // @ts-ignore - Mixin method
            this.checkout_trackOnBookingComplete(
              {
                id: order_reference,
                affiliation: getAppVariable('partner_name'),
                revenue: this.checkout_orderTotal,
                coupon: '',
              },
              {
                name: this.checkout_orderName,
                id: this.checkout_cartItem.id,
                price: this.checkout_orderTotal,
                category: this.checkout_cartItem.product_code,
                quantity: 1, // Quantity, set to 1 for MVP
              }
            );

            // @ts-ignore - Mixin method
            this.checkout_onSuccessCleaningStorage();
            // @ts-ignore - Mixin method
            this.checkout_setCookiesAndGoToReceipt(
              management_token,
              'BOOKING_RECEIPT'
            );
          }
        ).catch((error: { errorType: string; error: string; type: string }) => {

          if (status === ContinueBookingStatuses.Failed) {
            this.checkout_forwardInfo = null;
            this.commitUpdatePreloader('');
          } else {
            // @ts-ignore mixin method
            this.checkout_handlePaymentError(error);
          }
        });
    },

    /**
     * Go back to the Search page
     */
    goBackToSearch() {
      goToApp(
        getDomainUrl(DOMAINS_NAMES.ECOMMERCE),
        MANAGE_BOOKING_URLS.BOOKING_SEARCH,
        this.discountQuery,
      );
    },

    /**
     * Go back to the Travel Extras page, or Cross-sell Lounges page
     */
    goBackToExtras() {
      if (this.travelExtrasPageActive) {
        goToApp(
          getDomainUrl(DOMAINS_NAMES.ECOMMERCE),
          NAMED_ROUTES.travel_extras,
          this.discountQuery,
        );
      } else {
        goToApp(
          getDomainUrl(DOMAINS_NAMES.ECOMMERCE),
          MANAGE_BOOKING_URLS.SELECT_EXTRAS_LOUNGES,
          this.discountQuery,
        );
      }
    },

    /**
     * Go back to the Extras Product page if the cart contains an extras product,
     * or to the Search page otherwise.
     */
    goBack() {
      if (this.cartHasExtrasProduct()) {
        goToApp(
          getDomainUrl(DOMAINS_NAMES.ECOMMERCE),
          NAMED_ROUTES.select_extras.product,
          this.discountQuery,
        );
      } else {
        this.goBackToSearch();
      }
    },

    updateItems(items: CartItem[] | []) {
      this.checkout_cartItems = items;
    },

    updateOrderTotals(orderTotals: Cart['totals']) {
      this.checkout_total = orderTotals;
    },

    handleAddDiscountError() {
      // If we receive an error when adding a discount and another discount was added before that
      // We return the values to the original (without discount) from 'original_amounts' property

      if (this.checkout_cartItem.discount && this.discountCode) {
        this.clearDiscountCode();
        if (this.isParkingProduct) {
          this.checkout_total = this.checkout_originalAmounts;
          this.checkout_cartItems = [
            {
              ...this.checkout_cartItem,
              totals: this.checkout_cartItem.original_amounts.totals,
              fees: this.checkout_cartItem.original_amounts.fees,
              taxes: this.checkout_cartItem.original_amounts.taxes,
              discount: null,
            },
          ];
        } else if (this.isLoungesProduct) {
          this.checkout_total = this.checkout_originalAmounts;
          this.checkout_cartItems = this.cartItems
        }
      }
    },

    showPaymentFailedModal() {
      setTimeout(() => {
        this.$vueInstance.$openModal('GlobalModalError', {
          header: this.$vueInstance.$t('UI.modalError.paymentError.header'),
          body: this.$vueInstance.$t('UI.modalError.paymentError.body'),
          buttons: [
            {
              btnText: this.$vueInstance.$t('shared.buttons.close'),
            },
          ],
        });
      }, 500);
    },

    handleWidgetError() {
      // @ts-ignore mixin variable
      if (this.checkout_forwardInfo) {
        this.continueBooking(ContinueBookingStatuses.Failed);
        this.checkout_paymentIntentUpdated = false;
        this.showPaymentFailedModal();
      }
    },

    async addCancellationProtection(): Promise<void> {
      this.loadingPaymentInfo = true;
      await this.toggleCancellationProtection(this.cancellationProtectionProductIds, true);
      this.loadingPaymentInfo = false;
      if (this.checkout_isWindcavePayment) {
        await this.checkout_handleWindcaveReload();
      }
    },

    async removeCancellationProtection(): Promise<void> {
      this.loadingPaymentInfo = true;
      await this.toggleCancellationProtection(this.cancellationProtectionProductIds, false);
      this.loadingPaymentInfo = false;
      if (this.checkout_isWindcavePayment) {
        await this.checkout_handleWindcaveReload();
      }
    },

    updateCancellationProtection(): void {
      const productIdsWithoutCancellationProtection = this.cancellationProtectionProductIds.filter(productId => {
        const product = this.cartItems.find(item => item.id === productId);
        return product && !product.is_protected;
      });
      this.toggleCancellationProtection(productIdsWithoutCancellationProtection, true);
    },

    async toggleCancellationProtection(productIds: string[], isProtected: boolean): Promise<void> {
      const toggleCancellationProtectionRecursive = async (index: number) => {
        if (index >= productIds.length) {
          return;
        }

        const productId = productIds[index];
        const { totals, payable } = await updateCancellationProtectionRequest<
            ExtrasCancellationProtectionProduct,
            Cart['totals'],
            Cart['payable']
        >(productId, this.checkout_cartToken, {
          is_protected: isProtected,
        });

        this.checkout_total = totals;
        const deposit = useDepositStore();
        deposit.updateCartDepositTotal({...payable });
        this.commitUpdateCancellationProtection({ productId, isProtected });

        await toggleCancellationProtectionRecursive(index + 1);
      };

      await toggleCancellationProtectionRecursive(0);
    }
  },
});
