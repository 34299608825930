
// Packages
import { defineComponent, PropType } from 'vue';
import { mapState } from 'pinia';
import { PortalTarget } from 'portal-vue';

// Helpers
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { getAppPaymentProvider } from '@white-label-helper/get-app-payment-provider';
import {
  goToApp,
  getDomainUrl,
  ACCOUNT_URLS,
  DOMAINS_NAMES,
} from '@white-label-helper/switch-app';
import { checkIsLessOneHour } from '@white-label-helper/time-helpers';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';
import {
  required,
  alphaNumSpace,
  notNumber,
  requiredIf,
  email,
  maxLength
} from '@white-label-helper/vuelidate';
import { guessTimezone } from '@white-label-helper/date-utilities';
import { smoothScroll } from '@white-label-helper/scroll';

// Constants
import { PAYMENT_PROVIDERS } from '@white-label-configuration/constants';

// Mixins
import checkoutForm from '@white-label-helper/mixin-checkout-form';

// Stores
import { useMarketingPreferencesStore } from '@white-label-store/marketing-preferences';

// Types
import type { LocaleMessages } from 'vue-i18n';
import type { Partners } from '@white-label-types/partners';
import type { CookieSerializeOptions } from 'cookie';
import type {
  BraintreeConfig,
  StripeConfig,
  CartItem,
  WindcaveConfig,
} from '@white-label-types/parking-checkout';
import type { UserData } from '@white-label-types/stores';

// Components
import IconMessage from '../icon-message/icon-message.vue';
import ControlButton from '../control-button/control-button.vue';
import TextField from '../text-field/text-field.vue';
import PaymentPolicies from '../payment-policies/payment-policies.vue';
import PaymentGatewayBraintree from '../payment-gateway-braintree/payment-gateway-braintree.vue';
import PaymentGatewayStripe from '../payment-gateway-stripe/payment-gateway-stripe.vue';
import PaymentGatewayWindcave from '../payment-gateway-windcave/payment-gateway-windcave.vue';
import PaymentMarketingChannels from '../payment-marketing-channels/payment-marketing-channels.vue';
import Checkbox from '../checkbox/checkbox.vue';
import PhoneField from '../phone-field/phone-field.vue';

export default defineComponent({
  name: 'PaymentFormManageBooking',

  components: {
    ControlButton,
    Policies: PaymentPolicies,
    Checkbox: Checkbox,
    PaymentGatewaysSharedBraintree: PaymentGatewayBraintree,
    PaymentGatewaysSharedStripe: PaymentGatewayStripe,
    PaymentGatewaysSharedWindcave: PaymentGatewayWindcave,
    IconAlert: () => import('@white-label-icon/icon-alert'),
    IconLockStar: () => import('@white-label-icon/icon-lock-star'),
    IconMessage,
    TextField,
    PhoneField,
    MarketingChannels: PaymentMarketingChannels,
    PortalTarget,
  },

  mixins: [checkoutForm],

  props: {
    paymentsConfig: {
      type: Object as PropType<BraintreeConfig | StripeConfig | WindcaveConfig>,
      required: true,
    },

    paymentsConfigReceived: {
      type: Boolean,
      required: true,
      default: false,
    },

    paymentIntentUpdated: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: '',
    },

    isPriceEqualToOriginalBooking: {
      type: Boolean,
      default: false,
    },

    paymentNeeded: {
      type: Boolean,
      default: false,
    },

    orderPrice: {
      type: Number,
      default: 0,
    },

    userData: {
      type: Object as PropType<{ [key: string]: any }>,
      required: true,
    },

    amountToPay: {
      type: Number,
      required: true,
    },

    cancellationPolicies: {
      type: Array as PropType<
        CartItem['inventory_option']['cancellation_policies']
      >,
      default: () => [],
    },

    amendmentPolicies: {
      type: Array as PropType<
        CartItem['inventory_option']['amendment_policies']
      >,
      default: () => [],
    },

    entryDateTime: {
      type: String,
      default: '',
    },

    isLoungesProduct: {
      type: Boolean,
      default: false,
    },
  },

  validations() {

    const userDetailsValidations =    {
      first_name: {
        required,
        notNumber,
      },
      last_name: {
        required,
        notNumber,
      },
      license_plate: {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired('plate_number');
        }),
        alphaNumSpace(val: string) {
          // Fix when an empty field is considered an error
          // We have a separate validation to check if this field is required
          if (val) {
            return alphaNumSpace(val);
          }
          return true;
        },
      },
      phone: {
        phone_number: {
          required: requiredIf(function checkIfRequired() {
            // @ts-ignore - `this` is not recognized in its context
            return (
              this.checkoutForm_checkIfFieldRequired('phone_number') ||
              // @ts-ignore - `this` is not recognized in its context
              ( this.userDetails.marketing_channels?.sms && this.checkoutForm_checkFieldVisibility('phone_number') )
            );
          }),

          isValid(): boolean {
            // @ts-ignore - `this` is not recognized in its context
            const isEmpty = !this.userDetails.phone?.phone_number?.length;
            // @ts-ignore - `this` is not recognized in its context
            return !isEmpty ? this.phoneValid : true;
          },
        },
      },
      zipcode: {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired('zipcode');
        }),
      },
      outbound_flight: {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired(
            'outbound_flight_number'
          );
        }),
      },
      inbound_flight: {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired(
            'inbound_flight_number'
          );
        }),
      },
      ...(isBookingPortal
        ? {
            others_email: {
              email,
            },
            booker_email: {
              required: requiredIf(() => isBookingPortal),
              email,
            },
          }
        : {}),
    }

    if(this.isVehicleFieldsFeatureFlagEnabled) {
      userDetailsValidations.vehicle_make = {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired('vehicle_make');
        }),
        maxLength: maxLength(this.vehicleFieldsMaxLength)

      }
      userDetailsValidations.vehicle_model = {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired('vehicle_model');
        }),
        maxLength: maxLength(this.vehicleFieldsMaxLength)
      }
      userDetailsValidations.vehicle_colour= {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired('vehicle_colour');
        }),
        maxLength: maxLength(this.vehicleFieldsMaxLength)
      }
    }

    return {
      userDetails: userDetailsValidations
    }
  }
,

  data() {
    const defaultData = {
      showMarketingPreferences: false,
      buttonLoading: false,
      userDetails: {
        first_name: '',
        last_name: '',
        email: '',
        phone: {
          phone_number: '',
          formatted_number: '',
          country_code: '',
        },
        license_plate: '',

        zipcode: '',
        outbound_flight: '',
        inbound_flight: '',
        send_booker_email: false,
        send_customer_email: false,
        send_others_email: false,
        marketing_channels: {},
      } as UserData,
      phoneValid: false,
      isBookingPortal,
      others_email: '',
      booker_email: '',
      showDropdownExpanded: false,
    };

    if(this.$launchDarkly.variation('GT_2824_DAILY_HOURLY_BOOKING_REPORTS_ADDITIONAL_INFORMATION') === true) {
        defaultData.userDetails.vehicle_make= ''
        defaultData.userDetails.vehicle_model= ''
        defaultData.userDetails.vehicle_colour= ''
     }
    return defaultData;
  },

  computed: {
    ...mapState(useMarketingPreferencesStore, {
      readUserMarketingSubscription: 'readUserMarketingSubscription',
      readMarketingPreferences: 'readMarketingPreferences',
    }),

    displayBraintreeGateway(): boolean {
      return (
        getAppPaymentProvider() === PAYMENT_PROVIDERS.BRAINTREE &&
        this.paymentsConfigReceived
      );
    },

    displayStripeGateway(): boolean {
      return (
        getAppPaymentProvider() === PAYMENT_PROVIDERS.STRIPE &&
        this.paymentsConfigReceived
      );
    },

    displayWindcaveGateway(): boolean {
      const isWindcavePayment = this.$launchDarkly.variation('Enable_Windcave_Setup') &&
      getAppPaymentProvider() === PAYMENT_PROVIDERS.WINDCAVE;
      return isWindcavePayment && this.paymentsConfigReceived;
    },

    payButtonText(): LocaleMessages | string {
      const baseTranslationString = 'UI.button';
      let translationString = 'payForBooking';

      // @ts-ignore - Mixin method
      if (this.checkoutForm_paymentMethod === 'paypal')
        translationString = 'paypal';

      if (!this.paymentNeeded || this.isBookingPortal)
        translationString = 'updateBooking';

      return this.$t(`${baseTranslationString}.${translationString}`);
    },

    payButtonId(): string {
      let btnId = '';

      // @ts-ignore - Mixin method
      if (this.checkoutForm_paymentMethod === 'card')
        btnId = 'GAPaymentSubmitCard';
      // @ts-ignore - Mixin method
      if (this.checkoutForm_paymentMethod === 'paypal')
        btnId = 'GAPaymentSubmitPaypal';

      if (!this.paymentNeeded) btnId = 'GAUpdateBooking';

      return this.isPriceEqualToOriginalBooking ? '' : btnId;
    },

    partnerTimeZone(): Partners['poi']['timezone'] {
      return getAppVariable('poi.timezone') || guessTimezone();
    },

    userMarketingSubscription() {
      return this.readUserMarketingSubscription;
    },

    partnerMarketingPreferences() {
      return this.readMarketingPreferences;
    },
    isVehicleFieldsFeatureFlagEnabled():boolean {
      return this.$launchDarkly.variation('GT_2824_DAILY_HOURLY_BOOKING_REPORTS_ADDITIONAL_INFORMATION') === true
    },
    vehicleFieldsMaxLength(): number {
      return 25
    }
  },

  created(){
    this.userDetails = {
      ...this.userDetails,
      ...this.userData,
      others_email: this.userData.booking_emails?.others_email || '',
      booker_email: this.userData.booking_emails?.booker_email || '',
      send_booker_email:
        this.userData.booking_emails?.send_booker_email || false,
      send_customer_email:
        this.userData.booking_emails?.send_customer_email || false,
      send_others_email:
        this.userData.booking_emails?.send_others_email || false,
      first_name: this.userData.user_first_name,
      last_name: this.userData.user_last_name,
      // @ts-ignore mixin method
      phone: this.checkoutForm_checkFieldVisibility('phone_number')
        ? { ...this.userData.phone }
        : { ...this.userDetails.phone },
      // @ts-ignore mixin method
      license_plate: this.checkoutForm_checkFieldVisibility('plate_number')
        ? this.userData.license_plate
        : '',
      // @ts-ignore mixin method
      zipcode: this.checkoutForm_checkFieldVisibility('zipcode')
        ? this.userData.user_zipcode
        : '',
      // @ts-ignore mixin method
      outbound_flight:
        this.checkoutForm_checkFieldVisibility('outbound_flight_number') ||
        this.isLoungesProduct
          ? this.userData.outbound_flight
          : '',
      // @ts-ignore mixin method
      inbound_flight: this.checkoutForm_checkFieldVisibility(
        'inbound_flight_number'
      )
        ? this.userData.inbound_flight
        : '',
    };

    this.addVehicleFieldsToUserDetails()
  },
  mounted() {
    const phoneNumberFieldVisible = this.checkoutForm_checkFieldVisibility('phone_number');
    const savedUserMarketingChannels = this.userMarketingSubscription?.marketing_channels;

    if (this.userDetails.marketing_channels) {
      if (this.isMarketingChannelAvailable('email')) {
        this.userDetails.marketing_channels.email =
          !!savedUserMarketingChannels?.email;
      }

      if (this.isMarketingChannelAvailable('sms')) {
        this.userDetails.marketing_channels.sms =
          !!savedUserMarketingChannels?.sms;
      }

      /**
      * Toggles the expansion state of the marketing pref dropdown
      * based on the availability of the phone number field and user preferences.
      */
      if (this.userDetails.marketing_channels) {
        const { sms, email } = this.userDetails.marketing_channels;
        this.showDropdownExpanded = phoneNumberFieldVisible ? !(sms && email) : !email;
      }

      this.showMarketingPreferences = true;
    }
  },

  methods: {
    onFormFieldBlur(field: string): void {
      // @ts-ignore - no types for user details validation object
      this.$v.userDetails[field].$touch();
    },
    isMarketingChannelAvailable(channel: string): boolean {
      return !!this.partnerMarketingPreferences?.marketing_channels?.includes(
        channel
      );
    },

    validateForm() {
      this.$v.$touch();

      if (this.$v.$error) {
        smoothScroll('.input-container--error');
        return;
      }

      if (checkIsLessOneHour(this.entryDateTime, this.partnerTimeZone)) {
        const options = {
          path: '/',
          maxAge: 9999,
        } as CookieSerializeOptions;

        if (process.env.NODE_ENV !== 'production') {
          // if process run on 'localhost' we need to share token in sameSite
          options.sameSite = true;
        } else {
          // if process run on 'AWS' we need to share token in domain
          options.domain = process.env.NUXT_ENV_DOMAIN;
        }

        this.$cookies.set('crossAppError', 'lessOneHour', options);
        goToApp(
          getDomainUrl(DOMAINS_NAMES.ACCOUNT),
          ACCOUNT_URLS.MANAGE_BOOKING
        );
        return;
      }

      if (!this.displayWindcaveGateway) {
        this.buttonLoading = true;
      }

      if (!this.paymentNeeded || this.isBookingPortal) {
        // @ts-ignore - Mixin method
        this.checkoutForm_handlePaymentCompleted({
          payment_method_id: 'zero',
          merchant_id: 'zero',
        });
      } else {
        // @ts-ignore
        this.$refs.paymentGateway.submitForm();
      }
    },

    errorHandler() {
      this.buttonLoading = false;
      this.$emit('error');
    },
    addVehicleFieldsToUserDetails(){
      if(this.isVehicleFieldsFeatureFlagEnabled) {
        this.userDetails.vehicle_make = this.checkoutForm_checkFieldVisibility('vehicle_make')
        ? this.userData.vehicle_make
        : ''
        this.userDetails.vehicle_model= this.checkoutForm_checkFieldVisibility('vehicle_model')
        ? this.userData.vehicle_model
        : ''
        this.userDetails.vehicle_colour= this.checkoutForm_checkFieldVisibility('vehicle_colour')
        ? this.userData.vehicle_colour
        : ''
     }
    }
  },
});
