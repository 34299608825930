import { defineStore } from 'pinia';

// Types
import type { UserData } from '@white-label-types/stores';

export interface PaymentFormState {
  formData: null | UserData,
  timestamp: null | string,
}

export type StoreFormDataPayload = {
  formData: UserData,
  timestamp?: string
}

/**
 * Payment form store definition
 *
 * @returns {StoreDefinition}
 */
export const usePaymentFormStore = defineStore({
  id: 'paymentForm',

  actions: {
    commitStoreFormData(payload: StoreFormDataPayload) {
      this.formData = { ...payload.formData };
      if (payload.timestamp) this.timestamp = payload.timestamp;
    },

    commitClearFormData() {
      this.formData = null;
      this.timestamp = null;
    },
  },

  getters: {
    readPaymentFormData: (state) => state.formData,
  },

  state: (): PaymentFormState => ({
    formData: null,
    timestamp: null,
  }),

  persist: {
    storage: sessionStorage,
  },
});
