
// Packages
import { defineComponent, PropType } from 'vue';

// Components
import BTicketWrap from '../b-ticket-wrap/b-ticket-wrap.vue';
import TicketPreloader from '../ticket-preloader/ticket-preloader.vue';
import BTicketHeader from '../b-ticket-header/b-ticket-header.vue';
import BTicketBookingDetails from '../b-ticket-booking-details/b-ticket-booking-details.vue';
import BTicketAccessInfoGuests from '../b-ticket-access-info-guests/b-ticket-access-info-guests.vue';
import BTicketTimeInfo from '../b-ticket-time-info/b-ticket-time-info.vue';
import BTicketAccessInfo from '../b-ticket-access-info/b-ticket-access-info.vue';
import BTicketLocationInfo from '../b-ticket-location-info/b-ticket-location-info.vue';
import BTicketPolicies from '../b-ticket-policies/b-ticket-policies.vue';
import BTicketDirectionsInformation from '../b-ticket-directions-information/b-ticket-directions-information.vue';
import PrivateLoungeInfo from '../b-ticket-private-lounge-details/b-ticket-private-lounge-details.vue';

// Types
import type {
  ReceiptProduct,
  PurchaseProduct,
} from '@white-label-types/parking-booking';
import type { GeoLocation } from '@white-label-types/location';

// Constants
import { BARRIER_ACCESS_TYPES, PRODUCT_TYPES } from '@white-label-configuration/constants';

export default defineComponent({
  name: 'BTicket',

  components: {
    BTicketBookingDetails,
    BTicketWrap,
    BTicketHeader,
    TicketPreloader,
    BTicketAccessInfoGuests,
    BTicketTimeInfo,
    BTicketAccessInfo,
    BTicketLocationInfo,
    PrivateLoungeInfo,
    BTicketPolicies,
    BTicketDirectionsInformation,
    IconButton: () => import('@white-label-icon/icon-button'),
  },

  props: {
    ticketData: {
      type: Object as PropType<ReceiptProduct | PurchaseProduct>,
      required: true,
    },

    orderReference: {
      type: String,
      default: '',
    },

    hideAccessSection: {
      type: Boolean,
      default: false,
    },

    hideViewTicketsButton: {
      type: Boolean,
      default: false,
    },
    accessInfoFromEntranceInfo: {
      type: Boolean,
      default: false,
    },

    contentLoading: {
      type: Boolean,
      default: false,
    },

    subItems: {
      type: Array,
      default() {
        return [];
      },
    },

    hideLocationInfo: {
      type: Boolean,
    },

    isEntryExitProduct: {
      type: Boolean,
      default: false,
    },

    collapsible: {
      type: Boolean,
      default: false,
    },

    showDirections: {
      type: Boolean,
      default: false,
    },

    showPolicies: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    productName(): string {
      if ('parkingName' in this.ticketData) return this.ticketData.parkingName;
      if ('inventory_item' in this.ticketData)
        return this.ticketData.inventory_item?.display_name;
      return '';
    },
    productOption(): string {
      return this.ticketData?.inventoryItem?.product_option?.code;
    },
    isPrivateLounge(): boolean {
      return this.productOption === 'lounge_private';
    },
    isExtras(): boolean {
      return this.ticketData?.product_code === PRODUCT_TYPES.EXTRAS;
    },
    hideOnMobileForExtras(): boolean {
      return this.isMobileResolution && this.isExtras;
    },
    isAccessInfoExists(): boolean {
      return (
        (Array.isArray(this.ticketData?.entry_methods) &&
          this.ticketData?.entry_methods.length) ||
        (this.accessInfoFromEntranceInfo &&
          this.ticketData?.inventory_item?.entrance_info) ||
        (this.accessInfoFromEntranceInfo &&
          this.ticketData?.inventoryItem?.entrance_info)
      );
    },
    showGroupsOnMobile(): boolean {
      return this.subOptions?.length > 0 && this.isMobileResolution;
    },
    accessInfo(): string {
      if ('inventory_item' in this.ticketData) {
        return this.ticketData?.inventory_item?.entrance_info;
      }

      return this.ticketData?.inventoryItem.entrance_info;
    },
    locationInfo(): Pick<GeoLocation, 'latitude' | 'longitude'> {
      if ('inventory_item' in this.ticketData) {
        return this.ticketData.inventory_item.geo;
      }
      return this.ticketData.inventoryItem.geo;
    },
    airportName(): string {
      let name = '';
      let code = '';
      if ('locationInfo' in this.ticketData) {
        name = this.ticketData.locationInfo?.name;
        code = this.ticketData.locationInfo?.code;
      } else if ('poi' in this.ticketData) {
        name = this.ticketData.poi?.name;
        code = this.ticketData.poi?.code;
      }

      return `${name} (${code})`;
    },
    entryDateTime(): string {
      return (
        this.ticketData.entryDateTime ||
        this.ticketData.criteria?.started_at?.datetime ||
        this.ticketData.product?.started_at?.datetime
      );
    },
    exitDateTime(): string {
      return (
        this.ticketData.exitDateTime ||
        this.ticketData.criteria?.closed_at?.datetime ||
        this.ticketData.product?.closed_at?.datetime
      );
    },
    // Temporary fix
    subOptions() {
      if (this.subItems?.length > 0) {
        return this.subItems;
      }
      if (this.ticketData.product_code === PRODUCT_TYPES.EXTRAS){
         return [{ title: this.ticketData.inventory_option.sub_options[0].details.name_formatted }];
      }
      return this.ticketData.inventory_option?.sub_options
        ?.map((product) => {
          const quantity = product.totals.quantity;
          const guests = this.$tc(`shared.customers.${product.details.name}`, quantity);
          return {
            title: `${quantity} x ${guests}`,
          };
        }) || [];
    },
    cancellationPolicy(): Policy[] {
      if (this.ticketData.cancellation_protection) {
        return this.ticketData.cancellation_protection.cancellation_policies;
      }
      return this.ticketData.inventory_option?.cancellation_policies;
    },
    ticketIsExpanded(): boolean {
      return !this.collapsible || this.isOpen;
    },
  },
  methods: {
    getSubItems(data: any) {
      return data?.map(
        (item: any) =>
          `${item?.totals?.quantity} x ${this.capitalize(item?.details?.name)}`
      );
    },
    capitalize(item: string): string {
      return item.charAt(0).toUpperCase() + item.slice(1);
    },
    displayAccessSection(accessType: string): boolean {
      return (
        !this.hideAccessSection &&
        (accessType === BARRIER_ACCESS_TYPES.QRCODE ||
          accessType === BARRIER_ACCESS_TYPES.PINCODE)
      );
    },
  },
});
