import { httpCart } from "@white-label-configuration/http"
import type { BookingFee } from '@white-label-types/parking-booking';
import { parseCartResponse } from '@white-label-helper/data-parsers-cart';
import { datadogRum } from "@datadog/browser-rum";

type Meta = {
    original_order_item_id?: number | string
    original_order_item_total_price?: number
    pii_data_token?: string
    filter?: {
        index: number,
        item_list_name: string,
    },
}

export interface AddToCartData {
    cartToken: string,
    searchProductResultId: string,
    inventoryOptionId: number,
    searchToken?: string,
    couponCode?: string | null,
    originalOrderItemIsProtected?: boolean,
    language?: string,
    meta?: Meta,
    subOptionId?: string;
}

interface AddToCartBody {
    search_product_result_id: string,
    inventory_option_id: number,
    customer_id: null,
    search_token?: string,
    original_order_item_is_protected?: boolean,
    coupon_code?: string,
    language?: string,
    meta?: Meta,
    sub_option_id?: number | string,
}

interface UpdateCancellationProtectionBody {
    is_protected: boolean
}

// @ts-expect-error property
httpCart.onRequest((config) => {
    const session_id = datadogRum.getInternalContext()?.session_id || null;
    config.headers['X-Datadog-Session-ID'] = session_id;
});

export const fetchCartItems = (cartToken: string) => (
    httpCart.get(`public/cart/${cartToken}`)
        .then(({ body }) => body.data)
);

export const fetchCart = <I, T, P = unknown>(cartToken: string): Promise<{ items: I[], item: I, totals: T, booking_fee?: BookingFee, payable: P }> => (
    httpCart.get(`public/cart/${cartToken}`).then((res) => parseCartResponse<I, T, P>(res))
)
export const addToCartRequest = (cartToken: string, body: AddToCartBody) => (
    httpCart.post(`public/cart/${cartToken}/items`, { body })
);

export const addToCart = <I, T, P = unknown>({
    cartToken,
    searchProductResultId,
    inventoryOptionId,
    originalOrderItemIsProtected,
    meta,
    couponCode,
    searchToken,
    language,
    subOptionId,
    bundleId,
}: AddToCartData): Promise<{ items: I[], item: I, totals: T, booking_fee?: BookingFee, payable: P }> => {
    const body: AddToCartBody = {
        search_product_result_id: searchProductResultId,
        inventory_option_id: inventoryOptionId,
        customer_id: null,
        search_token: searchToken,
        language,
    };
    if (originalOrderItemIsProtected) {
        body.original_order_item_is_protected = originalOrderItemIsProtected;
    }
    if (couponCode) {
        body.coupon_code = couponCode;
    }
    if (meta) {
        body.meta = { ...meta };
    }
    if (subOptionId) {
        body.sub_option_id = subOptionId;
    }
    if (bundleId) {
        body.bundle_id = bundleId;
    }
    return httpCart.post(`public/cart/${cartToken}/items`, { body }).then((res) => parseCartResponse<I, T, P>(res));
};

export const deleteCartItem = <I, T, P>(
    cartToken: string,
    itemId: string,
): Promise<{ items: I[], item: I, totals: T, payable: P }> => httpCart.delete(`/public/cart/${cartToken}/items/${itemId}`).then((res) => parseCartResponse<I, T, P>(res));


export const updateCancellationProtectionRequest = <I, T, P>(
    cartId: string,
    cartToken: string,
    body: UpdateCancellationProtectionBody,
): Promise<{ items: I[], item: I, totals: T, payable: P }> => httpCart.patch(`/public/cart/${cartToken}/item/${cartId}/protection/update`, { body }).then((res) => parseCartResponse<I, T, P>(res));
