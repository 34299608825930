
import { defineComponent } from 'vue';
import { datadogRum } from '@datadog/browser-rum';

// Helpers
import { myAccountResendReceipt } from '@white-label-helper/api-parkings-receipt';
import { resendConfirmation } from '@white-label-helper/api-manage-booking';
import { format, DATE_TIME_FORMATS } from '@white-label-helper/date-utilities';
import { email, required } from '@white-label-helper/vuelidate';
import auth from '@white-label-helper/mixin-auth';

// Components
import ModalCenter from '../modal-center/modal-center.vue';
import TextDropdown from '../text-dropdown/text-dropdown.vue';
import Button from '../button/button.vue';

export default defineComponent({
  name: 'ManageBookingModalEmailReceipt',

  components: {
    ModalCenter,
    TextDropdown,
    ButtonControl: Button,
  },

  mixins: [auth],

  props: {
    orderId: {
      type: String,
      required: true,
    },
    orderEmail: {
      type: String,
      required: true,
    },
    entryDate: {
      type: String,
      required: true,
    },
    emailOptions: {
      type: Array,
      required: true,
    },
  },

  validations: {
    formData: {
      email: { required, email },
    },
  },

  data() {
    return {
      formData: {
        email: '',
      },
      loading: false,
      closeButtonId: 'MBEmailReceiptClose',
    };
  },

  computed: {
    getEmailDropdownItems(): any[] {
      return [...this.emailOptions];
    },
    userIsLoggedIn(): boolean {
      return this.getToken && this.isAuth0UserLoggedIn;
    },
    targetEmailAddress(): string {
      return this.userIsLoggedIn ? this.formData.email : this.orderEmail;
    },
  },

  methods: {
    async sendEmailReceipt(): Promise<void> {
      // Only need to validate if logged in, as unauth user can only resend to original order email.
      if (this.userIsLoggedIn) {
        this.$v.$touch();
        if (this.$v.$error) return;
      }
      if (this.orderId && (this.formData.email || this.orderEmail)) {
        this.loading = true;
        try {
          if (this.userIsLoggedIn) {
            const shortenedOrderId = this.orderId.split('-')[1];
            await myAccountResendReceipt({
              token: this.getToken,
              order_id: +shortenedOrderId,
              email: this.formData.email,
            });
          } else {
            const formattedDate = format(
              new Date(this.entryDate),
              DATE_TIME_FORMATS.year_month_day
            );
            await resendConfirmation({
              email: this.orderEmail,
              entry_date: formattedDate,
              order_reference: this.orderId
            });
          }
          this.$openNotification(
            this.$t('manageBooking.emailReceiptModal.success', {
              email: this.targetEmailAddress,
            })
          );
        } catch (error: any) {
          this.$openModal('GlobalModalError', {
            header: this.$t('shared.modals.errors.technicalIssue.header'),
            body: this.$t('shared.modals.errors.technicalIssue.body'),
            btnText: this.$t('shared.buttons.tryAgain'),
            btnType: 'custom',
          });
          datadogRum.addError(error);
        }
        this.closeModal();
      }
      this.loading = false;
    },
    closeModal(): void {
      this.$emit('close');
    },
  },
});
